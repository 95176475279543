import { computed, ref, shallowRef } from "vue";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";

import type { Ref } from "vue";
import type { NavigationGuardNext } from "vue-router";

export function useHasChanges<T>(origin: Ref<T>, saveAndExit?: Function) {

  const copy: Ref<T> = shallowRef<T>(cloneDeep(origin.value));

  const hasChanges = computed(() => !isEqual(origin.value, copy.value))

  const showPopConfirm = ref(false);
  let next : NavigationGuardNext;

  function updateCopy(value?: any) {
    copy.value = cloneDeep(value ? value : origin.value)
  }

  function checkChanges(_next: NavigationGuardNext) {
    if (hasChanges.value) {
      openPopConfirm(true, _next);
    } else {
      _next()
    }
  }

  function openPopConfirm(value: boolean, _next: NavigationGuardNext) {
    showPopConfirm.value = value;
    next = _next;
  }

  function popConfirmEvent(event: string) {
    switch (event) {
      case 'dontSaveAndExit': {
        if (next) next();
        break;
      }
      case 'saveAndExit': {
        if (saveAndExit && saveAndExit()) {
          if (next) next();
        }
        break;
      }
    }
    showPopConfirm.value = false;
  }

  return {
    hasChanges,
    showPopConfirm,
    popConfirmEvent,
    checkChanges,
    updateCopy,
  }
}
